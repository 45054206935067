import React from 'react';
import { Link, meta } from 'react-website';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import './Accessibility.scss';

import { metaKey } from '../utils/helpers';

@meta(() => ({
  ...metaKey('title', 'American Legal Publishing Accessibility Statement'),
}))
export default class Accessibility extends React.Component {
  render () {
    return (
      <div className="accessibility">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <Link to="/accessibility">Accessibility</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="am-page am-page--scroll">
          <div className="am-page__content am-page__content--nobg">
            <h2 className="accessibility-header">Accessibility Statement for American Legal Publishing</h2>
            <p>
              We want everyone who visits the American Legal Publishing
              website to feel welcome and find the experience rewarding.
            </p>
            <h3>What are we doing?</h3>
            <p>
              To help us make the American Legal Publishing website a positive place for everyone,
              we&apos;ve been using the&nbsp;
              <a
                aria-label="External link http://www.w3.org/TR/WCAG/"
                href="http://www.w3.org/TR/WCAG/"
              >
                Web Content Accessibility Guidelines (WCAG) 2.1
              </a>.
              These guidelines explain how to make web content more accessible for people with disabilities
              and more user friendly for everyone.
            </p>
            <p>
              The guidelines have three levels of accessibility (A, AA and AAA).
              We’ve chosen Level AA as the target for the American Legal Publishing website.
            </p>
            <h3>How are we doing?</h3>
            <p>
              We&apos;re working hard to achieve our goal of Level AA accessibility,
              but we realize there are some areas that still need improving.
              The following information explains what we&apos;re doing to make that happen.
            </p>
            <h3>Success criteria 1.1.1:</h3>
            <p>
              Non-text content has a text alternative for the American Legal Publishing implemented content.
              Certain municipalities may not yet contain text alternatives for all aspects of their codes.
              For text alternative concerns, contact the municipality in question or 
              American Legal Publishing.
            </p>
            <h3>Success criteria 1.3.1:</h3>
            <p>
              Tables in some municipalities may lack proper relationship structure.
              For text alternative concerns, contact the municipality in question or 
              American Legal Publishing.
            </p>
            <h3>Let us know what you think</h3>
            <p>
              If you enjoyed using the American Legal Publishing website,
              or if you had trouble with any part of it, please get in touch by sending us an email:&nbsp;
              <a href="mailto:techsupport@amlegal.com">
                techsupport@amlegal.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
